<template>
    <b-container fluid>
        <!-- Search -->
        <iq-card>
           <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('research_manage.research_project_report') }} {{ $t('globalTrans.search') }}</h4>
            </template>

            <template v-slot:body>
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(loadData)" @reset.prevent="reset">
                    <b-row>
                        <!-- <b-col xl="4" lg="4" sm="12" xs="12">
                            <ValidationProvider name="project status" vid="activity_status">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="activity_status"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                    {{$t('external_research.project_status')}}
                                </template>
                                <b-form-select
                                    plain
                                    v-model="search.activity_status"
                                    :options="projectStatusList"
                                    id="activity_status"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                        </b-col> -->
                        <b-col xl="6" lg="6" sm="12" xs="12">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                :label="$t('research_manage.thematic_area')"
                                label-for="thematic_area_id"
                                >
                                <b-form-select
                                plain
                                v-model="search.thematic_area_id"
                                :options="thematicAreaList"
                                id="thematic_area_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                                </template>
                                </b-form-select>
                                </b-form-group>
                        </b-col>
                        <b-col xl="6" lg="6" sm="12" xs="12">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="proposal_status"
                            >
                                <template v-slot:label>
                                    {{$t('external_research.proposal_status')}}
                                </template>
                                <b-form-select
                                    plain
                                    v-model="search.proposal_status"
                                    :options="proposalStatusList"
                                    id="proposal_status"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col xl="6" lg="6" sm="12" xs="12">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="from_date"
                            >
                              <template v-slot:label>
                                {{$t('publication_management.from_date')}}
                              </template>
                              <b-form-input
                                  plain
                                  v-model="search.from_date"
                                  id="from_date"
                                  :placeholder="$t('publication_management.from_date')"
                              >
                              </b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col lg="6" md="6" sm="12">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="to_date"
                                >
                                <template v-slot:label>
                                    {{ $t('publication_management.to_date') }}
                                </template>
                                <b-form-input
                                    id="to_date"
                                    plain
                                    v-model="search.to_date"
                                    :placeholder="$t('publication_management.to_date')"
                                >
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <div class="col text-right">
                            <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.search') }}</b-button>
                        </div>
                    </b-row>
                </b-form>
                </ValidationObserver>
            </template>
        </iq-card>
        <!-- /Search -->

        <!-- Report Data -->
        <b-row>
          <b-col md="12">
            <b-overlay :show="loadingData">

                <b-row v-if='reportData.length > 0'>
                    <b-col md="12">
                        <iq-card>
                            <template v-slot:headerTitle>
                                <h4 class="card-title">{{ $t('research_manage.research_project_report') }}  {{ $t('globalTrans.list') }}</h4>
                            </template>

                            <template v-slot:headerAction>
                                <b-button @click="pdfExport" class="btn_add_new">
                                    <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                                </b-button>
                            </template>

                            <template v-slot:body>
                                <b-overlay>
                                    <b-row mt-5>
                                        <b-col md="12" class="table-responsive">
                                            <div style="border: 2px solid;margin:10px;padding:10px">

                                                <!-- List Report Head Component -->
                                                <b-row>
                                                    <b-col>
                                                    <list-report-head :base-url="agriResearchServiceBaseUrl" uri="/report-heading/detail" :org-id="4">
                                                        <template v-slot:projectNameSlot>
                                                        </template>
                                                        <!-- <template v-slot:default>
                                                            {{ $t('publication_management.pub_report') }}
                                                        </template> -->
                                                         <div class="titleDiv">
                                                          <span class="text-center text-dark reportTitle" style="font-weight:bold;">{{ $t('research_manage.research_project_report') }}</span><br>
                                                         </div>
                                                    </list-report-head>
                                                    </b-col>
                                                </b-row>

                                                <!-- Search Parameters for Report -->
                                                <b-row>
                                                    <b-col>
                                                        <div class="text-center">
                                                            <table style="width:100%;color:black;">
                                                                <!-- <tr v-if="search.activity_status">
                                                                    <td align="right" style="width:45%">{{ $t('external_research.project_status') }}</td>
                                                                    <td align="center" style="width:5%">:</td>
                                                                    <td align="left" style="width:50%">{{ getProjectStatusList(search.activity_status) }}</td>
                                                                </tr> -->
                                                                 <tr v-if="search.proposal_status">
                                                                    <td align="right" style="width:45%">{{ $t('external_research.proposal_status') }}</td>
                                                                    <td align="center" style="width:5%">:</td>
                                                                    <!-- <td align="left" style="width:50%">{{ getProposalStatusList(search.proposal_status) }}</td> -->
                                                                    <td align="left" style="width:50%">{{ search.proposal_status }}</td>
                                                                </tr>
                                                                 <tr v-if="search.thematic_area_id">
                                                                    <td align="right" style="width:45%">{{ $t('research_manage.thematic_area_name') }}</td>
                                                                    <td align="center" style="width:5%">:</td>
                                                                    <td align="left" style="width:50%">{{ getThematicArea(search.thematic_area_id) }}</td>
                                                                </tr>
                                                                <tr v-if="search.from_date">
                                                                    <td align="right" style="width:45%">{{ $t('globalTrans.from_date') }}</td>
                                                                    <td align="center" style="width:5%">:</td>
                                                                    <td align="left" style="width:50%">{{ search.from_date | dateFormat }}</td>
                                                                </tr>
                                                                <tr v-if="search.to_date">
                                                                    <td align="right" style="width:45%">{{ $t('globalTrans.to_date') }}</td>
                                                                    <td align="center" style="width:5%">:</td>
                                                                    <td align="left" style="width:50%">{{ search.to_date | dateFormat }}</td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </b-col>
                                                </b-row>

                                                <!-- Report Data -->
                                                <b-row>
                                                    <b-col>
                                                    <b-table-simple class="tg mt-3" bordered hover small caption-top responsive v-if="reportData.length > 0">
                                                        <b-thead>
                                                             <b-tr>
                                                                <b-th style="vertical-align: top">{{ $t('globalTrans.sl_no') }}</b-th>
                                                                <b-th class="text-center">{{ $t('research_manage.project_id') }}</b-th>
                                                                <b-th class="text-center">{{ $t('research_manage.project_title') }}</b-th>
                                                                <b-th class="text-center">{{ $t('research_manage.thematic_area') }}</b-th>
                                                                <b-th class="text-center">{{ $t('research_manage.proposal_submission_date') }}</b-th>
                                                                <b-th class="text-center">{{ $t('external_research.principal_investigator_name') }}</b-th>
                                                                <b-th class="text-center">{{ $t('external_research.principal_investigator_organization') }}</b-th>
                                                                <b-th class="text-center">{{ $t('external_research.total_project_budget') }}</b-th>
                                                                <b-th class="text-center">{{ $t('research_manage.project_start_date') }}</b-th>
                                                                <b-th class="text-center">{{ $t('research_manage.project_end_date') }}</b-th>
                                                                <b-th class="text-center">{{ $t('external_research.project_duration') }}</b-th>
                                                                <b-th class="text-center">{{ $t('external_research.proposal_status') }}</b-th>
                                                                <!-- <b-th class="text-center">{{ $t('external_research.project_status') }}</b-th> -->
                                                            </b-tr>
                                                        </b-thead>
                                                        <b-tbody v-for="(data, index) in reportData" :key="index">
                                                            <tr v-for="(itm, indx) in data.investigators" :key="indx" class="mytable">
                                                                <b-td v-if="indx == 0" :rowspan="data.investigators.length" class="text-center">{{ $n(index + 1) }}</b-td>
                                                                <b-td v-if="indx == 0" :rowspan="data.investigators.length" class="text-center">{{ data.proposal_auto_id }}</b-td>
                                                                <b-td v-if="indx == 0" :rowspan="data.investigators.length" class="text-center">{{ (currentLocale === 'en') ? data.project_title : data.project_title_bn }}</b-td>
                                                                <b-td v-if="indx == 0" :rowspan="data.investigators.length" class="text-center">{{ getThematicArea(data.thematic_area_id) }}</b-td>
                                                                <b-td v-if="indx == 0" :rowspan="data.investigators.length" class="text-center">{{ data.created_at | dateFormat }}</b-td>
                                                                <b-td class="text-center">
                                                                    <span>
                                                                        {{ (currentLocale === 'en') ? itm.investigator_name : itm.investigator_name_bn }}
                                                                    </span>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                        <span>
                                                                        {{ getComponentOrgName(itm.org_type, itm.component_org_id) }}
                                                                        </span>
                                                                </b-td>
                                                                <b-td v-if="indx == 0" :rowspan="data.investigators.length" class="text-center">{{ $n(data.total_budget) }}</b-td>
                                                                <b-td v-if="indx == 0" :rowspan="data.investigators.length" class="text-center">{{ data.from_date | dateFormat }}</b-td>
                                                                <b-td v-if="indx == 0" :rowspan="data.investigators.length" class="text-center">{{ data.to_date | dateFormat }}</b-td>
                                                                <b-td v-if="indx == 0" :rowspan="data.investigators.length" class="text-center">{{ getDuration(data.from_date, data.to_date) }}</b-td>
                                                                <b-td v-if="indx == 0" :rowspan="data.investigators.length" class="text-center">{{ getProposalStatusList(data.proposal_status) }}</b-td>
                                                                <!-- <b-td v-if="indx == 0" :rowspan="data.investigators.length" class="text-center">{{ data.proposal_status }}</b-td> -->
                                                                <!-- <b-td v-if="indx == 0" :rowspan="data.investigators.length" class="text-center">{{ getProjectStatusList(data.activity_status) }}</b-td> -->
                                                            </tr>
                                                        </b-tbody>
                                                    </b-table-simple>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-overlay>
                            </template>
                        </iq-card>
                    </b-col>
                </b-row>
                <!-- Else -->
                <div v-else class="panel-body text-center mt-3">
                    <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
                </div>
            </b-overlay>
          </b-col>
        </b-row>
        <!-- /Report Data -->
    </b-container>
</template>
<script>

import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import flatpickr from 'flatpickr'
import { researchProjectReportList } from '../../api/routes'
import ExportPdf from './export_pdf_list'
// import moment from 'moment'
export default {
    components: {
        ListReportHead
    },
    created () {
        if (this.$route.query.status > 0) {
            this.search.proposal_status = this.$route.query.status
        }
    },
    mounted () {
        flatpickr('#from_date', {})
        flatpickr('#to_date', {})
    },
    data () {
        return {
            agriResearchServiceBaseUrl,
            search: {
                from_date: '',
                // activity_status: '',
                proposal_status: '',
                thematic_area_id: 0,
                to_date: ''
            },
             params: {
                proposal_status: '',
                // activity_status: '',
                thematic_area_id: '',
                from_date: '',
                to_date: ''
            },
            reportData: [],
            loadingData: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save')
        }
    },
    computed: {
        thematicAreaList: function () {
            return this.$store.state.AgriResearch.commonObj.thematicAreaList.filter(item => item.status === 1)
        },
        // projectStatusList: function () {
        // return this.$store.state.AgriResearch.commonObj.projectStatusList.map(item => {
        //     return Object.assign({}, item, { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
        // })
        // },
        proposalStatusList: function () {
            return this.$store.state.AgriResearch.commonObj.proposalStatusList.map(item => {
                return Object.assign({}, item, { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
            })
        },
        currentLocale () {
            return this.$i18n.locale
        }
    },
    watch: {
       //
    },
    methods: {
        getDuration (startingDate, endingDate) {
            var startDate = new Date(new Date(startingDate).toISOString().substr(0, 10))
            if (!endingDate) {
                endingDate = new Date().toISOString().substr(0, 10)
            }
            var endDate = new Date(endingDate)
            if (startDate > endDate) {
                var swap = startDate
                startDate = endDate
                endDate = swap
            }
            var startYear = startDate.getFullYear()
            var february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28
            var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

            var yearDiff = endDate.getFullYear() - startYear
            var monthDiff = endDate.getMonth() - startDate.getMonth()
            if (monthDiff < 0) {
                yearDiff--
                monthDiff += 12
            }
            var dayDiff = endDate.getDate() - startDate.getDate()
            if (dayDiff < 0) {
                if (monthDiff > 0) {
                    monthDiff--
                } else {
                    yearDiff--
                    monthDiff = 11
                }
                dayDiff += daysInMonth[startDate.getMonth()]
            }

            if (this.$i18n.locale === 'bn') {
                const year = this.$n(yearDiff, { useGrouping: false })
                const month = this.$n(monthDiff, { useGrouping: false })
                const day = this.$n(dayDiff, { useGrouping: false })
                const result = year + ' বছর, ' + month + ' মাস, ' + day + ' দিন'
                return result
            } else {
                const result = yearDiff + ' Year, ' + monthDiff + ' Month, ' + dayDiff + ' Days'
                return result
            }
        },
        async loadData () {
            // this.params.activity_status = this.search.activity_status
            this.params.proposal_status = this.search.proposal_status
            this.params.thematic_area_id = this.search.thematic_area_id
            this.params.to_date = this.search.to_date
            this.params.from_date = this.search.from_date

            this.loadingData = true
            const result = await RestApi.getData(agriResearchServiceBaseUrl, researchProjectReportList, this.search)
            if (result.success && result.data) {
                this.loadingData = false
                this.reportData = result.data
            } else {
               this.reportData = []
               this.loadingData = false
            }
        },
         getThematicAreaList () {
            return this.$store.state.AgriResearch.commonObj.thematicAreaList.filter(data => data.status === 1 && data.thematic_area_id === this.search.thematic_area_id)
        },
         getMonthBnName (monthId) {
            const objectData = this.$store.state.commonObj.banglaMonthList.find(type => type.value === monthId)
            if (this.$i18n.locale === 'bn') {
                return objectData.text_bn
            } else {
                return objectData.text_en
            }
        },
        getComponentOrgName (type, Id) {
            if (type === 1) {
                const newObj = this.$store.state.AgriResearch.commonObj.componentOrgList.find(item => item.value === Id)
                const Obj = this.$store.state.orgList.find(item => item.value === newObj.org_id)
                return this.$i18n.locale === 'en' ? Obj.text_en : Obj.text_bn
            } else {
                const Obj = this.$store.state.AgriResearch.commonObj.componentOrgList.find(item => item.value === Id)
                return this.$i18n.locale === 'en' ? Obj.text_en : Obj.text_bn
            }
        },
         getThematicArea (contentId) {
            const objectData = this.$store.state.AgriResearch.commonObj.thematicAreaList.find(type => type.value === contentId)
            if (this.$i18n.locale === 'bn') {
                return objectData.text_bn
            } else {
                return objectData.text_en
            }
        },
         getProposalStatusList (contentId) {
            const objectData = this.$store.state.AgriResearch.commonObj.proposalStatusList.find(type => type.value === contentId)
            if (this.$i18n.locale === 'bn') {
                return objectData.text_bn
            } else {
                return objectData.text_en
            }
        },
        // getProjectStatusList (contentId) {
        //     const objectData = this.$store.state.AgriResearch.commonObj.projectStatusList.find(type => type.value === contentId)
        //     if (this.$i18n.locale === 'bn') {
        //         return objectData.text_bn
        //     } else {
        //         return objectData.text_en
        //     }
        // },
        pdfExport () {
          const reportTitle = this.$t('research_manage.research_project_report')
          ExportPdf.exportPdfDetails(agriResearchServiceBaseUrl, '/report-heading/detail', 4, reportTitle, this.reportData, this, this.search)
      }
    }
}
</script>
<style scoped>
.reportTitle {
  font-weight: bold;
    border: 1px solid;
    padding: 8px 15px 8px 15px;
    border-radius: 11px;
}
.titleDiv {
  margin-top: 1.2rem;
  font-size: 18px;
}
.mytable > td {
    font-size: 12px !important;
}
</style>
