import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
// import ReportHeading from '@/Utils/report-head-traning'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

// function getComponentOrg (reportdata) {
//   let productName = ''
//   Object.entries(reportdata).forEach(([key, value]) => {
//     Object.keys(value).forEach((value) => {
//       const productId = parseInt(value)
//       const product = Store.state.AgriResearch.commonObj.componentOrgList.find(item => item.value === productId)
//       if (i18n.locale === 'bn') {
//         productName = product.text_bn
//       } else {
//         productName = product.text_en
//       }
//     })
//   })
//   return productName
// }

// function componentOrgName (data) {
// //   if (type === 1) {
// //     const newObj = this.$store.state.AgriResearch.commonObj.componentOrgList.find(item => item.value === Id)
// //     const Obj = this.$store.state.orgList.find(item => item.value === newObj.org_id)
// //     return this.$i18n.locale === 'en' ? Obj.text_en : Obj.text_bn
// // } else {
// //     const Obj = this.$store.state.AgriResearch.commonObj.componentOrgList.find(item => item.value === Id)
// //     return this.$i18n.locale === 'en' ? Obj.text_en : Obj.text_bn
// // }
//   // let productName = ''
//   // Object.entries(reportdata).forEach(([key, value]) => {
//   //   Object.keys(value).forEach((value) => {
//   //     const productId = parseInt(value)
//   //     const product = Store.state.AgriResearch.commonObj.componentOrgList.find(item => item.value === productId)
//   //     if (i18n.locale === 'bn') {
//   //       productName = product.text_bn
//   //     } else {
//   //       productName = product.text_en
//   //     }
//   //   })
//   // })
//   // return productName
// }

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, reportData, vm, search) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [{
        columns: reportHeadData.reportHeadColumn
        },
        { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
        { text: reportHeadData.address, style: 'address', alignment: 'center' },
        { text: reportTitle, style: 'hh', alignment: 'center' }
    ]
        const allRowsHead = []
        // if (search.activity_status) {
        //   const rowItem = [
        //     { text: vm.$t('external_research.project_status'), style: 'td', alignment: 'right' },
        //     { text: ':', style: 'td', alignment: 'center' },
        //     { text: vm.getProjectStatusList(search.activity_status), style: 'td', alignment: 'left' }
        //   ]
        //   allRowsHead.push(rowItem)
        // }
        if (search.proposal_status) {
          const rowItem = [
            { text: vm.$t('external_research.proposal_status'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.getProposalStatusList(search.proposal_status), style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.pub_title_id) {
          const rowItem = [
            { text: vm.$t('research_manage.thematic_area_name'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.getThematicArea(search.thematic_area_id), style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.from_date) {
          const rowItem = [
            { text: vm.$t('globalTrans.from_date'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: dateFormat(search.from_date), style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.to_date) {
          const rowItem = [
            { text: vm.$t('globalTrans.to_date'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: dateFormat(search.to_date), style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (allRowsHead.length > 0) {
          pdfContent.push({
            table: {
              widths: ['100%'],
              body: allRowsHead
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          })
        }
        pdfContent.push({ text: '', style: 'fertilizer' })
        const allRows = [
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('research_manage.project_id'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('research_manage.project_title'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('research_manage.thematic_area'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('research_manage.proposal_submission_date'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('external_research.total_project_budget'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('research_manage.project_start_date'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('research_manage.project_end_date'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('external_research.project_duration'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('external_research.proposal_status'), style: 'th', alignment: 'center', bold: true }
          ]
        ]
        reportData.forEach((data, index) => {
          allRows.push([
            { text: vm.$n(index + 1), alignment: 'center', style: 'td' },
            { text: data.proposal_auto_id, alignment: 'center', style: 'td' },
            { text: data.project_title, alignment: 'center', style: 'td' },
            { text: vm.getThematicArea(data.thematic_area_id), alignment: 'center', style: 'td' },
            { text: dateFormat(data.created_at), alignment: 'center', style: 'td' },
            { text: data.total_budget, alignment: 'center', style: 'td' },
            { text: dateFormat(data.from_date), alignment: 'center', style: 'td' },
            { text: dateFormat(data.to_date), alignment: 'center', style: 'td' },
            { text: vm.getDuration(data.from_date, data.to_date), alignment: 'center', style: 'td' },
            { text: vm.getProposalStatusList(data.proposal_status), alignment: 'center', style: 'td' }
          ])
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['5%', '5%', '16%', '20%', '8%', '8%', '8%', '8%', '8%', '8%', '8%', '12%', '*'],
            body: allRows
          }
        })
        pdfContent.push({ text: '', style: 'fertilizer' })
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Landscape',
        styles: {
          th: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 9 : 8,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n.locale === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
              fontSize: 18,
              bold: true,
              margin: [0, -10, 0, -20]
          },
          org: {
              fontSize: 13,
              bold: true,
              margin: [0, -25, 10, 5]
          },
          address: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          hh: {
              fontSize: 13,
              bold: true,
              margin: [10, 10, 25, 20]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('research-project-report')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
